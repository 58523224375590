import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Section,
  Heading,
  Columns,
  Container,
  Image,
  Content,
  Table,
} from 'react-bulma-components';
import './app.scss';

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spacetailor - Privacy Policy</title>
        <meta property="og:title" content="Spacetailor - Privacy Policy" />
        <meta
          property="og:description"
          content="Read the Privacy Policy of Spacetailor"
        />
        <meta property="og:url" content="https://www.spacetailor.io/privacy" />
      </Helmet>
      <div className="header header--privacy">
        <Container breakpoint="widescreen">
          <Columns>
            <Columns.Column size={12}>
              <Section className="logo">
                <Image
                  src={'/spacetailor.png'}
                  className="logotype"
                  alt="Spacetailor logo"
                />
              </Section>
            </Columns.Column>
          </Columns>
        </Container>
      </div>
      <div>
        <Container breakpoint="widescreen">
          <Columns>
            <Columns.Column size={12}>
              <Section>
                <Heading size={1}>Spacetailor Privacy Policy</Heading>
                <Heading subtitle size={3} renderAs="h2">
                  Last Updated: November 11, 2020
                </Heading>
                <Content>
                  <p>
                    Teamtailor AB ("we", "us", “our”) are committed to
                    protecting and respecting your privacy. This Privacy Policy
                    (together with any other documents referred to herein)
                    explains how we collect, use and share Personal Data of
                    users to our mobile application “Spacetailor” (the “App”).
                    By using the App you acknowledge that you have been provided
                    this Privacy Policy and agree to its terms and conditions.
                  </p>
                  <p>
                    “Personal Data” means any information that identifies or
                    relates to a particular individual and also includes
                    information referred to as “personally identifiable
                    information” under the General Data Protection Regulation
                    (the “GDPR”).
                  </p>
                  <p>
                    Please read the following carefully to understand our views
                    and practices regarding your Personal Data and how we will
                    treat it.
                  </p>
                </Content>

                <Content>
                  <h2>1. Teamtailor’s processing of personal data</h2>

                  <h3>Why do we process Personal Data?</h3>
                  <p>
                    The App is available for download in the App Store.
                    Companies can create an account in the App to administer
                    remote work guidelines, and their employees can use it to
                    schedule their work location status according to such
                    guidelines. The purpose is to enable employers to limit the
                    number of employees working from the office every day. The
                    Provision of the App, therefore, requires Teamtailor to
                    process Personal Data on behalf of the employer for their
                    employees when using the App, identifying and relating to
                    them as data subjects. Consequently, pursuant to the GDPR,
                    each company that signs up for the App is the ”Controller”
                    of their respective Personal Data and Teamtailor is engaged
                    as a ”Processor” acting under the authority, and on behalf
                    of the Controller.
                  </p>

                  <h3>Personal Data we collect and how we use it?</h3>
                  <p>
                    Teamtailor will use Personal Data only as necessary to
                    provide and deliver the App to all our users, to prevent or
                    address any service or technical issues, to respond to a
                    user’s support request, or for any other purpose provided
                    for with respect to the usage of the App, or in accordance
                    with or as may be required by law.
                  </p>
                  <p>
                    The processing activities subject to this Privacy Policy
                    include the following operations:
                  </p>
                  <ul>
                    <li>
                      Collecting of email addresses associated with the company
                      that creates an account with the App.
                    </li>
                    <li>
                      Collecting of Personal Data from App users to set up
                      personal accounts within the App.
                    </li>
                    <li>
                      Providing, operating, developing and maintaining the App.
                    </li>
                    <li>Addressing user questions and support requests.</li>
                    <li>
                      Managing the App, system administration, and security.
                    </li>
                    <li>
                      Sending technical alerts, updates, security notifications,
                      and other App-related communications to App users.
                    </li>
                    <li>
                      Investigating and preventing fraud, unauthorized access or
                      use of App, breaches of terms and policies, and other
                      wrongful behavior.
                    </li>
                  </ul>

                  <p>
                    The categories of Personal Data used for the purposes listed
                    above are:
                  </p>
                  <ul>
                    <li>Contact information including name and email.</li>
                    <li>The time for check-in to and from the office.</li>
                    <li>
                      Timezone and approximate location, if location-based
                      reminders are activated.
                    </li>
                  </ul>
                </Content>

                <Content>
                  <h2>2. Legal Basis</h2>
                  <p>
                    Teamtailor uses legitimate interests for processing Personal
                    Data of App users. Hence, we have made an overall assessment
                    where we have weighed our legitimate interests against your
                    interest and rights, with the conclusion that our interests
                    do not override your interest to use the App.
                  </p>
                </Content>

                <Content>
                  <h2>3. Disclosure of your Personal Data</h2>

                  <h3>With service providers</h3>
                  <p>
                    In order to provide the App, we use a number of service
                    providers. These service providers will use your Personal
                    Data only in accordance with our instructions and as
                    otherwise required by law, and they are required to maintain
                    the confidentiality of your information. A full list of
                    these third-party service providers can be found in{' '}
                    <a href="#appendix">Appendix 1</a> of this Privacy Policy.
                  </p>

                  <h3>Product specific privacy disclosures</h3>
                  <p>
                    We may provide links within the App to sites or services of
                    third parties. We are not responsible for the collection,
                    use, monitoring, storage or sharing of any Personal Data by
                    such third parties, and we encourage you to review those
                    third parties' privacy notices and ask them questions about
                    their privacy practices as they relate to you.
                  </p>

                  <h3>In accordance with legal requirements</h3>
                  <p>
                    We reserve the right to use or disclose your Personal Data
                    if required by law or if we reasonably believe that use or
                    disclosure is necessary to protect our rights, protect your
                    safety or the safety of others, investigate fraud, or comply
                    with a law, court order, or legal process.
                  </p>
                </Content>

                <Content>
                  <h2>4. How we store Personal Data</h2>

                  <h3>Where we store your Personal data</h3>
                  <p>
                    The Personal Data we collect from you and process is only
                    stored within, and on devices physically located within, the
                    EU/EEA, or such third country deemed to offer an adequate
                    level of security by the European Commission, or by
                    suppliers that have entered into binding agreements that
                    fully comply with the lawfulness of third country transfers.
                  </p>

                  <h3>For how long do we retain Personal Data?</h3>
                  <p>
                    Your Personal Data will be processed as long as it is
                    necessary for the purposes linked to the Personal Data and
                    we have a legal ground to process your personal data. Upon
                    your written request to us (see our contact details in
                    Section 9 below), your Personal Data will be deleted,
                    including any copies and backups, within 30 days.
                  </p>
                </Content>

                <Content>
                  <h2>5. Data subject requests</h2>
                  <p>
                    Under the{' '}
                    <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN">
                      General Data Protection Regulation
                    </a>
                    you have a number of important rights with respect to your
                    Personal Data. In summary, those rights include to access
                    your Personal Data, request from us to rectify any mistakes
                    in your information which we hold, require the erasure of
                    your Personal Data in certain situations, receive Personal
                    Data concerning you and have the right to transmit those
                    data to another service provider (data portability) in
                    certain situations. To safeguard your rights, you can
                    contact us on the information below.
                  </p>
                </Content>

                <Content>
                  <h2>6. Lodge a complaint</h2>
                  <p>
                    The GDPR gives you the right to lodge a complaint with a
                    supervisory authority, in particular in the European Union
                    (or EEA) in the state where you work, normally live or where
                    any alleged infringement of data protection laws occurred.
                    The supervisory authority in Sweden may be contacted at
                    <a href="https://www.datainspektionen.se/kontakta-oss/">
                      https://www.datainspektionen.se/kontakta-oss/
                    </a>
                    .
                  </p>
                </Content>

                <Content>
                  <h2>7. Security</h2>
                  <p>
                    As we are committed to always protecting your data, we will
                    take all reasonable contractual, legal, technical, and
                    organisational measures to ensure that your data is treated
                    securely and with an adequate level of protection compared
                    to and in line with as a minimum the level of protection
                    offered within the EU/EEA. We limit access to your Personal
                    Data to those who have a genuine business need to access it.
                    Those processing your Personal Data will do so only in an
                    authorised manner and are subject to a duty of
                    confidentiality
                  </p>
                </Content>

                <Content>
                  <h2>8. Cookies</h2>
                  <p>
                    We do not currently use cookies in the App. If we ever use
                    cookies in the App, this Privacy Policy will be updated and
                    further consent will be required from you before we set such
                    cookies.
                  </p>
                </Content>

                <Content>
                  <h2>9. Contact</h2>
                  <p>
                    If you wish to provide comments or questions about our
                    Privacy Policy, or exercise your rights, feel free to
                    contact us at <strong>support@teamtailor.com</strong>, or at
                    the mailing address below.
                  </p>
                  <p>
                    <address>
                      Teamtailor AB
                      <br />
                      ATTN: Customer Support
                      <br />
                      Östgötagatan 16
                      <br />
                      116 21 Stockholm
                    </address>
                  </p>
                </Content>

                <Content>
                  <h2>10. Changes</h2>
                  <p>
                    We have the right to change or add to this Privacy Policy.
                    The latest version of the Privacy Policy will always be
                    available on the App. The date at the top of this Privacy
                    Policy indicates when it was last updated.
                  </p>
                </Content>

                <Content>
                  <a name="appendix">&nbsp;</a>
                  <h2>APPENDIX 1</h2>
                  <h3>Existing and approved sub-processor</h3>

                  <Table>
                    <thead>
                      <tr>
                        <th>Company name</th>
                        <th>Type of services</th>
                        <th>Url</th>
                        <th>HQ</th>
                        <th>Data Center Location</th>
                        <th>Data they handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>nhost.io</td>
                        <td>Infrastructure as a Service</td>
                        <td>
                          <a href="https://nhost.io/">https://nhost.io/</a>
                        </td>
                        <td>Sweden</td>
                        <td>Netherlands</td>
                        <td>Database</td>
                      </tr>
                      <tr>
                        <td>Postmark, Wildbit, LLC</td>
                        <td>Communication Service Provider</td>
                        <td>
                          <a href="https://postmarkapp.com">
                            https://postmarkapp.com
                          </a>
                        </td>
                        <td>USA</td>
                        <td>USA (Standard Contractual Clauses)</td>
                        <td>All communication</td>
                      </tr>
                    </tbody>
                  </Table>
                </Content>
              </Section>
            </Columns.Column>
          </Columns>
        </Container>
      </div>
      <Section style={{ justifyContent: 'center', display: 'flex' }}>
        <a href="https://www.teamtailor.com/?utm_source=Spacetailor&utm_medium=web&utm_campaign=spacetailor">
          <Image
            src={'/from_teamtailor.png'}
            style={{ width: 208 }}
            alt="From Teamtailor"
          />
        </a>
      </Section>
    </>
  );
}
